import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IOrganigrama } from "../../../../interface/services";
import { AedasLoading } from "../../../Shared/AedasLoading/AedasLoading";
import { ShowOrganizationChart } from "../../../Shared/ShowOrganigramaChart/ShowOrganizationChart";
import { getNewOrgCompany } from "../../../../Services/NewOrgService";
import { Leyend } from "../../../../Visual/components/shared/Leyend";
import { findPeopleInDt } from "../../../../Utils/findInDt";

export function CurrentOrgChart() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentOrgChart, setCurrentOrgChart] = useState<any>();
  const [message, setMessage] = useState<string>("");
  const [leyend, setShowLeyend] = useState(false);
  const { company_id }: { company_id: string } = useParams();

  useEffect(() => {
    //@ts-ignore
    getNewOrgCompany(company_id)
      .then((res: IOrganigrama) => {
        console.log(res);
        setCurrentOrgChart(res);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error === 404) {
          setMessage("Todavía no hay ningún organigrama publicado.");
          setIsLoading(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowLeyend(findPeopleInDt(currentOrgChart));
  }, [currentOrgChart]);

  useEffect(() => {
    console.log("cambia", currentOrgChart);
  }, [currentOrgChart]);
  return (
    <>
      {isLoading ? (
        <AedasLoading />
      ) : (
        <>
          <h2
            className="p-3"
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            {message}
          </h2>

          <>
            {leyend && (
              <div style={{ marginBottom: "1rem" }}>
                <Leyend showLeyendDt={leyend} />
              </div>
            )}
            <ShowOrganizationChart
              orgChart={currentOrgChart}
              setOrgChart={setCurrentOrgChart}
            ></ShowOrganizationChart>
          </>
        </>
      )}
    </>
  );
}
