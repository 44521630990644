import {
  GridCellEditCommitParams,
  GridCellParams,
  GridColDef,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";

export function useTableEdit(props: any) {
  const { rows, columns, setEdit, onCellClick } = props;
  const [columsEditTable, setColumsEditTable] = useState<GridColDef[]>([]);
  const [rowsEditTable, setRowsEditTable] = useState<any[]>([]);
  const [selectionModel, setSelectionModel] = useState<any[]>([]);

  useEffect(() => {
    processData();
  }, [rows]);

  const processData = () => {
    processColumns();
    processRows();
  };

  /**
   *  En la tabla de ediccion, añade la clase isEditable a las columnas editables para que tengan un fondo mas oscuro y se puedan distinguir
   */
  const processColumns = () => {
    const arrayColums: GridColDef[] = [];
    columns.map((column: GridColDef) => {
      column.cellClassName = (params: GridCellParams) => {
        return params.isEditable ? "isEditable" : "";
      };
      arrayColums.push(column);
    });
    arrayColums.sort((a, b) => {
      return parseInt(a.field) < parseInt(b.field) ? -1 : 1;
    });
    setColumsEditTable(arrayColums);
  };

  /**
   * Suma los campos edited, removed, new, a las filas en la tabla de edicción para que los manejadores de eventos puedan detectar los cambios.
   */
  const processRows = () => {
    setRowsEditTable(rows);
    const arrayRows: any[] = [];
    rows.map((row: any) => {
      row.edited = false;
      row.removed = false;
      row.new = false;
      row.publish = false;
      arrayRows.push(row);
    });
    setRowsEditTable(arrayRows);
  };

  // TODO: Revision de este codigo, se tuvo que hacer rapido por prisas del cliente
  const handleAdd = () => {
    if (typeof onCellClick === "function") {
      onCellClick();
    } else if (typeof onCellClick === "undefined") {
      let newRow: any = {};
      columsEditTable.map((column: any) => {
        if (column.field === "id") {
          if (rowsEditTable.length === 0) {
            newRow[column.field] = 1;
          } else {
            let aux = [...rowsEditTable];
            aux = aux.sort((a, b) => a.id - b.id);
            newRow[column.field] = aux[aux.length - 1].id + 1;
          }
        } else {
          newRow[column.field] = null;
        }
        newRow.edited = false;
        newRow.removed = false;
        newRow.publish = false;
        newRow.new = true;
      });

      setRowsEditTable([...rowsEditTable, newRow]);
    }
  };

  /**
   *  Filtra una copia de las columnas, segun estan seleccionadas y las establece como removed
   */
  const handleRemove = () => {
    let copyRows = [...rowsEditTable];

    if (props.getRowId) {
      copyRows
        .filter((row) => selectionModel.find((model) => model === row.mongo_id))
        .map((row) => {
          row.removed = true;
        });
    }
    copyRows
      .filter((row) => selectionModel.find((model) => model === row.id))
      .map((row) => {
        row.removed = true;
      });

    setRowsEditTable(copyRows);
    setSelectionModel([]);
  };

  /**
   * Filtra cada una de las propiedades (edited[], new[], removed[]) de las filas,
   *  estos datos les llega a los componentes que renderizan la tabla en forma de objeto con las propiedades.
   */
  const handleSave = () => {
    let edited =
      rowsEditTable.filter(
        (row) =>
          row.edited === true &&
          row.new === false &&
          row.removed === false &&
          row.publish === false
      ) ?? [];
    let removed =
      rowsEditTable.filter(
        (row) =>
          row.removed === true && row.new === false && row.publish === false
      ) ?? [];

    let publish =
      rowsEditTable.filter(
        (row) =>
          row.publish === true && row.removed === false && row.new === false
      ) ?? [];

    let news = rowsEditTable.filter((row) => row.new === true) ?? [];
    props.handleSave({ edited, removed, news, publish });
    setEdit(false);
  };

  const handleCellEditCommit = async (params: GridCellEditCommitParams) => {
    let rowsCopy = [...rowsEditTable];
    /* @ts-ignore */
    rowsCopy[rowsCopy.findIndex((r) => r.id === params.id)][params.field] =
      params.value;
    rowsCopy[rowsCopy.findIndex((r) => r.id === params.id)].edited = true;
    setRowsEditTable(rowsCopy);
  };

  const handlePublish = () => {
    let copyRows = [...rowsEditTable];

    if (props.getRowId) {
      copyRows
        .filter((row) => selectionModel.find((model) => model === row.mongo_id))
        .map((row) => {
          row.publish = true;
        });
    }
    copyRows
      .filter((row) => selectionModel.find((model) => model === row.id))
      .map((row) => {
        row.publish = true;
      });

    console.log(copyRows);
    setRowsEditTable(copyRows);
    setSelectionModel([]);
  };

  return {
    handleSave,
    handleRemove,
    handleAdd,
    rowsEditTable,
    setRowsEditTable,
    selectionModel,
    setSelectionModel,
    columsEditTable,
    handleCellEditCommit,
    handlePublish,
  };
}
