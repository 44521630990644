import React from "react";

export const Leyend = ({
  showLeyendDt,
  showComite = false,
  showLeyendComite = false,
}: any) => {
  return (
    <>
      <div className="d-flex flex-md-row flex-column  justify-content-center">
        {showLeyendDt && (
          <div
            className="d-flex justify-content-start align-items-center"
            style={{ marginLeft: "20px" }}
          >
            <div
              style={{
                width: "25px",
                height: "4px",
                marginRight: "10px",
                backgroundColor: "orange",
              }}
            ></div>
            {showLeyendDt && <div>Empleado ubicado en DT </div>}
          </div>
        )}
        {showComite && (
          <>
            <div
              className="d-flex justify-content-start align-items-center"
              style={{ marginLeft: "20px" }}
            >
              <div
                style={{
                  width: "25px",
                  height: "4px",
                  marginRight: "10px",
                  backgroundColor: "#ffcc43",
                }}
              ></div>
              {showLeyendComite && <div>Comite de Inversiones</div>}
            </div>
            <div
              className="d-flex justify-content-start align-items-center"
              style={{ marginLeft: "20px" }}
            >
              <div
                style={{
                  width: "25px",
                  height: "4px",
                  marginRight: "10px",
                  backgroundColor: "#88DBDF",
                }}
              ></div>
              {showLeyendComite && <div>Comite de Negocio</div>}
            </div>
            <div
              className="d-flex justify-content-start align-items-center"
              style={{ marginLeft: "20px" }}
            >
              <div
                style={{
                  width: "25px",
                  height: "4px",
                  marginRight: "10px",
                  backgroundColor: "#FF7F32",
                }}
              ></div>
              {showLeyendComite && <div>Comite de Cumplimiento</div>}
            </div>
            <div
              className="d-flex justify-content-start align-items-center"
              style={{ marginLeft: "20px" }}
            >
              <div
                style={{
                  width: "25px",
                  height: "4px",
                  marginRight: "10px",
                  backgroundColor: `var(--aedas-lightgreen_comite_esg)`,
                }}
              ></div>
              {showLeyendComite && <div>Comite de ESG</div>}
            </div>
          </>
        )}
      </div>
    </>
  );
};
