/* eslint-disable*/
import { useEffect, useRef, useState } from "react";
import { TemporaryDrawer } from "../Drawer/Drawer";
// eslint-disable import/no-named-as-default

function NewOrg({ data, idUser, isDraft, showComite = false }) {
  const [assistantCount, setAssistantLCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const ref = useRef();
  const [copyData, setCopyData] = useState([...data]);
  useEffect(() => {
    const mappedObj = mapObjects(copyData);
    setCopyData(mappedObj);
  }, []);

  useEffect(() => {
    setCopyData([...data]);
  }, [data]);
  const mapObjects = (objects) => {
    let assistantCounter = 0;
    let hasAssistantR = false;
    let hasAssistantL = false;

    objects.forEach((obj) => {
      if (obj.tags && obj.tags.includes("assistant")) {
        assistantCounter += 1;
      }
      if (obj.tags && obj.tags.includes("assistantR")) {
        hasAssistantR = true;
      }
      if (obj.tags && obj.tags.includes("assistantL")) {
        hasAssistantL = true;
      }
    });
    setAssistantLCount(assistantCounter);

    return objects.map((obj) => {
      const newObj = { ...obj };
      if (
        newObj.tags &&
        newObj.tags.includes("assistant") &&
        assistantCounter >= 2
      ) {
        if (!hasAssistantR && !hasAssistantL) {
          newObj.tags.push("assistantL");
          hasAssistantL = true;
        } else if (hasAssistantR && !hasAssistantL) {
          newObj.tags.push("assistantL");
          hasAssistantL = true;
        } else if (!hasAssistantR && hasAssistantL) {
          newObj.tags.push("assistantR");
          hasAssistantR = true;
        }
      }
      return newObj;
    });
  };

  useEffect(() => {
    if (ref.current) {
      OrgChart.templates.myTemplate = Object.assign({}, OrgChart.templates.ana);
      OrgChart.templates.myTemplate.size = [56, 80];
      OrgChart.templates.myTemplate.plus = "";
      OrgChart.templates.myTemplate.minus = "";
      OrgChart.templates.myTemplate.html = "{val}";
      OrgChart.templates.myTemplate.nodeMenuButton = "";
      OrgChart.LINK_ROUNDED_CORNERS = 0;
      OrgChart.templates.myTemplate.node = "";
      OrgChart.templates.myTemplate.menuButton = isDraft
        ? '<div style="position:absolute;right:{p}px;top:{p}px; width:40px;height:50px;cursor:pointer;" data-ctrl-menu="">' +
          '<img src="/Assets/img/download.png"></img>'
        : "";
      OrgChart.templates.assistantL = Object.assign({}, OrgChart.templates.ana);
      OrgChart.templates.assistantL.size = [56, 80];
      OrgChart.templates.assistantL.plus = "";
      OrgChart.templates.assistantL.minus = "";
      OrgChart.templates.assistantL.html = "{val}";
      OrgChart.templates.assistantL.nodeMenuButton = "";
      OrgChart.templates.assistantL.node = "";
      OrgChart.templates.assistantL.menuButton = isDraft
        ? '<div style="position:absolute;right:{p}px;top:{p}px; width:40px;height:50px;cursor:pointer;" data-ctrl-menu="">' +
          '<img src="/Assets/img/download.png"></img>'
        : "";

      OrgChart.templates.assistantL.linkAdjuster = {
        fromX: 0,
        fromY: 20,
        toX: 5,
        toY: 20,
      };

      OrgChart.templates.assistantR = Object.assign({}, OrgChart.templates.ana);
      OrgChart.templates.assistantR.size = [56, 80];
      OrgChart.templates.assistantR.plus = "";
      OrgChart.templates.assistantR.minus = "";
      OrgChart.templates.assistantR.html = "{val}";
      OrgChart.templates.assistantR.nodeMenuButton = "";
      OrgChart.templates.assistantR.node = "";
      OrgChart.templates.assistantR.menuButton = isDraft
        ? '<div style="position:absolute;right:{p}px;top:{p}px; width:40px;height:50px;cursor:pointer;" data-ctrl-menu="">' +
          '<img src="/Assets/img/download.png"></img>'
        : "";
      OrgChart.templates.assistantR.linkAdjuster = {
        fromX: 0,
        fromY: -20,
        toX: -5,
        toY: -20,
      };

      let assistantCounter = 0;
      copyData.forEach((obj) => {
        if (obj.tags && obj.tags.includes("assistant")) {
          assistantCounter += 1;
        }
      });
      if (assistantCounter > 1) {
        OrgChart.templates.split.size = [10, 0];
        OrgChart.templates.split.node = "";
      } else {
        OrgChart.templates.split.size = [0, 0];
        OrgChart.templates.split.node = "";
      }

      const orgName = document.querySelector(".orgChart__title")?.textContent;
      const today = new Date();
      const day = today.getDate();
      const month = today.getMonth() + 1;
      const year = today.getFullYear();
      const formattedDate = day + "-" + month + "-" + year;
      const combinedName = orgName + "_" + formattedDate + ".pdf";

      const getInitialScale = () => {
        const routesScaleOne = [
          "/organigramas/organigrama-actual/1",
          "/organigrama/1",
          "organigramas/borradores/1",
        ];
        if (routesScaleOne.includes(new URL(window.location.href).pathname)) {
          return 1;
        } else {
          return OrgChart.match.boundary;
        }
      };

      var chart = new OrgChart(ref.current, {
        enableSearch: false,
        miniMap:
          new URL(window.location.href).pathname ===
          "/organigramas/organigrama-actual/1",
        levelSeparation: 12,
        template: "myTemplate",
        levelSeparation: 10,
        subtreeSeparation: 12,
        siblingSeparation: 10,
        enableDragDrop: false,
        showXScroll: OrgChart.scroll.visible,
        mouseScrool: OrgChart.action.xScroll,
        assistantSeparation: 20,
        align: OrgChart.align.orientation,
        menu: {
          pdf: {
            text: "Exportar en PDF",
            icon: OrgChart.icon.pdf(24, 24, "#7A7A7A"),
            onClick: () =>
              chart.exportPDF({
                format: "A4",
                header: orgName,
                filename: combinedName,
              }),
          },
          png: {
            text: "Exportar en PNG",
            icon: OrgChart.icon.png(24, 24, "#7A7A7A"),
            onClick: () =>
              chart.exportPNG({
                header: orgName,
                filename: combinedName,
              }),
          },
          svg: {
            text: "Exportar en SVG",
            icon: OrgChart.icon.svg(24, 24, "#7A7A7A"),
            onClick: () =>
              chart.exportSVG({
                header: orgName,
                filename: combinedName,
              }),
          },
        },

        tags: {
          assistantL: {
            template: "assistantL",
          },
          assistantR: {
            template: "assistantR",
          },
          sublevel0: {
            subLevels: 0,
          },
          sublevel1: {
            subLevels: 1,
          },
          sublevel2: {
            subLevels: 2,
          },
          sublevel3: {
            subLevels: 3,
          },
        },
        toolbar: {
          layout: true,
          zoom: true,
          fit: true,
          expandAll: false,
        },

        scaleInitial: getInitialScale(),
        nodeBinding: {
          field_0: "name",
          html: "name",
        },
      });
      chart.on("field", function (_, args) {
        args.value = getTemplateField(args);
      });

      chart.on("exportstart", function (_, args) {
        args.styles += ` <style id="myStyles">
                          .node_test {
                            background-color: rgb(252, 252, 252) !important;
                          }
                        </style>`;
      });

      OrgChart.events.on("prerender", function (_, args) {
        const newUrlCompleta = window.location.href;
        const newUrlObjeto = new URL(newUrlCompleta);
        const newCurrentPath = newUrlObjeto.pathname;

        const routesValid = [
          "/organigrama/etiqueta/2",
          "/organigrama/departamento/3",
          "/organigrama/departamento/2",
          "/organigrama/departamento/7",
          "/organigrama/departamento/13",
          "/organigrama/departamento/10",
          "/organigrama/departamento/8",
          "/organigrama/departamento/14",
          "/organigrama/departamento/6",
          "/organigrama/departamento/1",
          "/organigrama/organigrama/3",
        ];
        if (routesValid.includes(newCurrentPath)) {
          /**
           * Logica cedel centrado: coge el elemento del primer nivel mas alejado a la izquierda y lo divide entre 2, esa será la mitad
           */
          const nodesArray = Object.values(args.res.nodes);
          const highestX = Math.max(
            ...nodesArray
              .filter(
                (node) =>
                  // node.tags.includes("sublevel3") &&
                  node.templateName === "myTemplate"
              )
              .map((node) => node.x)
          );
          const parentNodeId = args.res.visibleNodeIds[0];
          args.res.nodes[parentNodeId].x = highestX / 2;
        }
      });
      chart.load(copyData);
    }
  }, [ref, copyData, assistantCount]);

  const handleSVGClick = (event) => {
    const clickedElement = event.target;

    // Función para buscar el atributo data-n-id en los elementos ascendentes
    const findParentWithDataNId = (element) => {
      if (element) {
        const nId = element.getAttribute("data-n-id");
        if (nId) {
          // Realiza alguna acción cuando se hace clic en un elemento con data-n-id
          setUserData(
            data.find((user) => {
              if (user.id == nId) {
                setIsOpen(true);
              }
              return user.id == nId;
            })
          );
          // Puedes pintar el elemento aquí o realizar cualquier acción deseada
        } else {
          // Si no se encontró el atributo, sigue buscando en el elemento padre
          findParentWithDataNId(element.parentElement);
        }
      }
    };

    // Llama a la función para buscar el atributo data-n-id en los elementos ascendentes
    findParentWithDataNId(clickedElement);
  };

  /**
   *
   * @param {*} args
   * @returns
   */
  const getTemplateField = (args) => {
    const isTBR = args?.data?.name.includes("TBR");

    return `<foreignobject class="node" x="0" y="0" width="56" height="80"><div onclick="handleDrawer(${
      args.data
    });" class="node_test" style="background-color:${
      args?.data?.id == idUser
        ? "rgb(186, 219, 230);"
        : isTBR
        ? "var(--toastify-color-success);"
        : "white;"
    };border-top: 4px solid var(--blue-lis);flex-direction: column;justify-content: initial;font-size: 7px;position: relative;width: 3.5rem;height: 5rem;cursor: pointer;display: flex;flex-direction: column;">${
      args?.data?.in_dt
        ? '<div style=" width: 100%; height: 2px; left: 0px; "><div style=" width: 100%; background: orange; height: 2px; "></div></div>'
        : ""
    }
        ${
          args?.data?.in_comite_inversiones && showComite
            ? '<div style="width: 100%; height: 2px; left: 0px; "><div style=" width: 100%; background: #ffcc43; height: 2px; "></div></div>'
            : ""
        }

        ${
          args?.data?.in_comite_negocio && showComite
            ? '<div style="width: 100%; height: 2px; left: 0px; "><div style=" width: 100%; background: #88DBDF; height: 2px; "></div></div>'
            : ""
        }
        ${
          args?.data?.in_comite_cumplimiento && showComite
            ? '<div style="width: 100%; height: 2px; left: 0px; "><div style=" width: 100%; background: #FF7F32; height: 2px; "></div></div>'
            : ""
        }

        ${
          args?.data?.in_comite_esg && showComite
            ? '<div style="width: 100%; height: 2px; left: 0px; "><div style=" width: 100%; background: var(--aedas-lightgreen_comite_esg); height: 2px; "></div></div>'
            : ""
        }

        
        <div lang="es" style="font-size: 8px;font-family: var(--lis-title);font-weight: 600;width: 99%;line-height: normal;text-align: center;margin-top: .5rem;margin-bottom: .1rem;">${
          args?.data?.name
        }</div><div lang="es" style="font-size: 6.5px;font-family: var(--lis-title);margin: 0px auto;color: ${
      isTBR ? "white" : "rgb(78, 95, 102)"
    };font-weight: 600;width: 99%;line-height: 1.1em;overflow-wrap: break-word;hyphens: auto;text-align: center;">${
      args?.data?.title
    }</div></div></foreignobject>`;
  };

  return (
    <>
      <TemporaryDrawer
        open={isOpen}
        setOpen={setIsOpen}
        data={userData}
        allData={data}
      ></TemporaryDrawer>

      <div
        data-is-draft={isDraft}
        style={{ background: "#e9e9e9" }}
        id="tree"
        ref={ref}
        onClick={handleSVGClick}
      ></div>
    </>
  );
}

export default NewOrg;
